/*--------------------
  TAB
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-tab {
  width: 150px;
  color: $gray500;

  path {
    stroke: $gray500;
    transition: stroke 0.15s linear;
  }

  &.selected {
    color: $gray600;
    border-color: $brand11;
    pointer-events: none;

    path {
      stroke: $gray600
    }
  }
}
