/*--------------------
  POCHATOK
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im {
  font-weight: $fw-base;
  font-size: $fz-base;
  line-height: $lh-base;
  font-family: $ff-base;
  color: $gray600;
  font-style: $fs-base;
  scroll-behavior: smooth;

  @include media($tabletL) {
    font-size: 15px;
    line-height: 22px;
  }
}

svg {
  display: block;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}
