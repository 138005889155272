/*--------------------
  MODAL
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-modal {
  position: fixed;
  inset: 0;
  z-index: 1040;
  display: none;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  background-color: rgba($gray600, 0.5);

  &.show {
    display: block;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 24px;
  }

  &__inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 564px;
    background-color: $white;
    border-radius: 8px;

    @include media($tabletL) {
      padding: 16px;
    }

    &--alert {
      max-width: 480px;
      padding: 24px;
      color: $brand21;
      text-align: center;
      border-radius: 12px;

      @include media($tabletL) {
        padding: 16px;
      }

      .pochatok-im-modal__body {
        padding: 20px;
        background-color: $brand23;
        border-radius: 12px;
      }
    }

    &--rotate-phone {
      max-width: 343px;
      padding: 16px;
      text-align: center;

      svg {
        @include all-size(40px);
        margin: 0 auto 8px;
      }
    }
  }

  &__header {
    margin-bottom: 24px;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  &__body {
    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__footer {
    margin-top: 24px;

    @include media($tabletL) {
      margin-top: 16px;
    }

    > * {
      width: 100%;
    }
  }

  &__error {
    margin: 24px 0;
    color: #f52323;

    @include media($tabletL) {
      margin: 16px 0;
    }
  }

  .pochatok-im-button--close {
    position: absolute;
    top: -24px;
    right: -24px;

    svg {
      @include all-size(16px);
    }

    path {
      stroke: $white;
    }
  }
}
