/*--------------------
  TEXT
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 62px;

  @include media($tabletL) {
    font-size: 36px;
    line-height: 42px;
  }
}

.pochatok-im-h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;

  @include media($tabletL) {
    font-size: 30px;
    line-height: 36px;
  }
}

.pochatok-im-h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;

  @include media($tabletL) {
    font-size: 22px;
    line-height: 28px;
  }
}

.pochatok-im-h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;

  @include media($tabletL) {
    font-size: 16px;
    line-height: 24px;
  }
}

.pochatok-im-h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;

  @include media($tabletL) {
    font-size: 16px;
    line-height: 24px;
  }
}

.pochatok-im-caption {
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  &[data-disabled="true"] {
    pointer-events: none;
    background-color: $gray200;
    color: $gray400;
  }
}
