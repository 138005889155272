/*--------------------
  SCHEMA
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-schema {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: auto;
  padding: 0 32px 32px;

  @include media($tabletL) {
    padding: 16px;
  }

  > svg {
    margin: 0 auto;

    > * {
      transition: fill 0.15s linear;
    }
  }

  g[aria-expanded] {
    cursor: pointer;
  }

  g {
    transition: filter 0.15s linear;
  }

  g[aria-expanded="true"] {
    filter: brightness(90%);
  }

  g[data-color="#a8bf34"] {
    @include svg-fill-color(#a8bf34, $white);
  }

  g[data-color="#b93221"] {
    @include svg-fill-color(#b93221, $white);
  }

  g[data-color="#024a22"] {
    @include svg-fill-color(#024a22, $white);
  }

  g[data-color="#ffc107"] {
    > * {
      &:first-child {
        fill: #ffc107;
      }
    }
  }

  g[data-color="#347fb9"] {
    @include svg-fill-color(#347fb9, $white);
  }

  g[data-color="#b92cb8"] {
    @include svg-fill-color(#b92cb8, $white);
  }

  g[data-disabled="true"] {
    pointer-events: none;
    @include svg-fill-color($gray200, $gray400);
  }

  g[data-free="true"] {
    @include svg-fill-color($white, $gray600);
  }

  &--pochatok {
    > svg {
      @include all-size(700px);
    }
  }

  &--pavilion-odesa {
    > svg {
      @include all-size(240px, 752px);
    }
  }

  &--pavilion-ukraine {
    > svg {
      @include all-size(240px, 820px);
    }
  }

  &--covered-market {
    > svg {
      @include all-size(72px, 756px);
    }
  }

  &--fruits-vegetables-market {
    > svg {
      @include all-size(232px, 726px);
    }
  }

  &--bacalia-market {
    > svg {
      @include all-size(578px, 686px);
    }
  }

  &--fish-market {
    > svg {
      @include all-size(570px, 675px);
    }
  }

  &--meat-market {
    > svg {
      @include all-size(298px, 646px);
    }
  }

  &--gallery-food {
    > svg {
      @include all-size(214px, 576px);
    }
  }

  &--manufactury-market {
    > svg {
      @include all-size(757px, 510px);
    }
  }
}
