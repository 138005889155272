/*--------------------
  PANEL
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 820px;
  overflow: auto;

  @include media($tabletL) {
    flex-direction: row;
    max-width: 100%;
    background-color: $white;
    border-radius: 8px;
  }

  @include media($tabletS) {
    flex-direction: column;
    background-color: transparent;
    border-radius: 0;
  }

  &__nav {
    padding: 32px 32px 40px;

    @include media($tabletL) {
      padding: 16px 16px 0;
    }
  }

  &__btn-back {
    margin-bottom: 8px;
  }

  &__tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -8px;
    margin-left: -8px;
    padding: 32px;

    @include media($tabletL) {
      padding: 16px 16px 0;
    }

    .pochatok-im-tab {
      @include media($tabletS) {
        width: 100%;
      }
    }

    > * {
      margin-bottom: 8px;
      margin-left: 8px;

      @include media($tabletS) {
        width: calc(50% - 8px);
      }
    }
  }

  &__btn-scroll {
    display: none;
    padding: 0 16px 16px;

    @include media($tabletS) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__legend {
    @include media($tabletL) {
      min-width: 40%;
    }

    @include media($tabletS) {
      margin-top: 16px;
      background-color: $white;
      border-radius: 8px;
    }
  }

  &__map {
    position: relative;

    @include media($tabletL) {
      width: 100%;
      min-width: 60%;
    }

    @include media($tabletS) {
      background-color: $white;
      border-radius: 8px;
    }

    &.admin {
      .pochatok-im-schema {
        padding: 32px;

        @include media($tabletL) {
          padding: 16px;
        }
      }
    }
  }
}
