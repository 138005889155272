/*--------------------
  PHOTO
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-photo {
  display: flex;
  justify-content: center;
  padding: 0 25px 32px;

  @include media($tabletL) {
    padding: 16px;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    overflow: auto;
    border-radius: 8px;
  }

  .pochatok-im-tag {
    transition: filter 0.15s linear;
    cursor: default;
  }

  &__tags {
    .pochatok-im-tag[aria-expanded] {
      cursor: pointer;
    }

    .pochatok-im-tag[aria-expanded="true"] {
      filter: brightness(90%);
    }
  }

  &__tag {
    position: absolute;
    top: 0;
  }

  img {
    display: block;
    max-width: inherit;
    border-radius: 8px;
  }

  .pochatok-im-tag {
    &--1 {
      top: 230px;
      left: 474px;
    }

    &--2 {
      top: 213px;
      left: 525px;
    }

    &--3 {
      top: 250px;
      left: 534px;
    }

    &--4 {
      top: 211px;
      left: 599px;
    }

    &--5 {
      top: 219px;
      left: 625px;
    }

    &--6 {
      top: 195px;
      left: 649px;
    }

    &--7 {
      top: 219px;
      left: 681px;
    }

    &--8 {
      top: 350px;
      left: 600px;
    }

    &--9 {
      top: 362px;
      left: 499px;
    }

    &--10 {
      top: 285px;
      left: 409px;
    }

    &--11 {
      top: 262px;
      left: 365px;
    }

    &--12 {
      top: 262px;
      left: 180px;
    }

    &--13 {
      top: 215px;
      left: 70px;
    }

    &--14 {
      top: 163px;
      left: 389px;
    }
  }
}
