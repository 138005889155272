/*--------------------
  COMMON STYLES
--------------------*/
@import "src/assets/styles/helpers/utils";

.visible-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.overflow-hidden {
  overflow: hidden;
}
