/*--------------------
  MAP
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-map {
  &__btn-filter {
    @include media($tabletL) {
      margin-bottom: 24px;
    }
  }

  &__title {
    margin-bottom: 40px;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    max-width: $container;
    min-height: 1130px;
    border-radius: 24px;
    overflow: hidden;
    background-color: $white;

    @include media($tabletL) {
      max-width: 100%;
      min-height: 100%;
      flex-direction: column;

      border-radius: 0;
      background-color: transparent;
    }
  }
}
