/*--------------------
  OPTIONS
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-options {
  height: 875px;
  overflow: auto;

  @include media($tabletL) {
    height: auto;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__label {
    position: relative;
    display: flex;
    cursor: pointer;
    font-weight: inherit;
  }

  &__checkbox:checked ~ .pochatok-im-options__indicator::before {
    background-color: $brand21;
    border-color: $brand21;
  }

  &__checkbox:checked ~ .pochatok-im-options__indicator::after {
    opacity: 1;
  }

  &__indicator {
    position: relative;
    padding: 4px;
    margin-right: 4px;

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 50%;
      display: block;
      opacity: 0;
      width: 10px;
      height: 6px;
      border-bottom: 2px solid $white;
      border-left: 2px solid $white;
      transform: translate(-50%, -50%) rotate(315deg);
      transition: opacity 0.15s linear;
    }

    &::before {
      content: "";
      display: block;
      width: 16px;
      min-width: 16px;
      height: 16px;
      border: 2px solid #DAE5DF;
      border-radius: 4px;
      transition: border 0.15s linear, background-color 0.15s linear;
    }
  }
}
