/*--------------------
  SEARCH
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-search {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border: 2px solid $gray300;
  border-radius: 8px;

  &__input {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    border: none;

    &::placeholder {
      color: $gray400;
    }
  }
}
