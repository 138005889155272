/*--------------------
  MIXINS
--------------------*/

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin all-size($width, $height: $width) {
  @include size($width, $height);
  min-width: $width;
  max-width: $width;
  min-height: $height;
  max-height: $height;
}

@mixin svg-fill-color($color, $color-last) {
  > *:not(g) {
    fill: $color;

    &:last-child {
      fill: $color-last;
    }

    &:first-child {
      fill: $color;
    }
  }
}

@mixin media($widthValue, $width: max-width) {
  @media only screen and ($width: $widthValue) {
    @content;
  }
}

@mixin font-face($font-name, $path, $weight: 400, $style: normal) {
  @font-face {
    font-weight: $weight;
    font-family: $font-name;
    font-style: $style;
    src: url(#{$path}.woff2) format("woff2"),
    url(#{$path}.woff) format("woff");
    font-display: swap;
    unicode-range: U+000-5FF; /* Latin glyphs */
  }
}
