/*--------------------
  SHOP
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-shop {
  &__btn-back {
    margin-bottom: 30px;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }

  img {
    border-radius: 12px;
  }

  &__info {
    flex-grow: 1;
    margin-bottom: -16px;

    @include media($tabletL) {
      margin-bottom: -8px;
    }

    > * {
      margin-bottom: 16px;

      @include media($tabletL) {
        margin-bottom: 8px;
      }
    }
  }

  &__card {
    padding: 24px;
    background-color: $white;
    border-radius: 8px;

    @include media($tabletL) {
      padding: 16px;
    }

    a {
      color: $gray600;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .pochatok-im-caption {
      margin-bottom: 4px;
    }
  }

  &__block {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__image {
    margin-right: 16px;
    max-width: 50%;
    width: 100%;

    @include media($tabletL) {
      max-width: 100%;
      margin-bottom: 16px;
      margin-right: 0;
    }
  }

  &__socials {
    display: flex;
    margin-top: 4px;
    margin-left: -8px;

    & > li {
      margin-left: 8px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      @include size(40px);
      min-width: 40px;
      border: 2px solid $gray300;
      border-radius: 90px;
      font-size: 0;
      transition: border-color 0.15s linear;

      &:hover,
      &:focus {
        border-color: $gray500;
      }
    }
  }

  &__content {
    display: flex;

    @include media($tabletL) {
      flex-direction: column;
    }
  }
}
