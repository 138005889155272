/*--------------------
  INDEX STYLES
--------------------*/
// HELPERS
// Variables and mixins
@import "helpers/utils";

// BASE
// Base styles
@import "base/fonts";
@import "base/common";

#pochatok-interactive-map {
  // LIBS
  // external libraries
  @import "libs/normalize";
  @import "libs/tippy";

  // COMPONENTS
  // Specific components like a slider, a loader, a widget
  @import "components/pochatok";
  @import "components/button";
  @import "components/text";
  @import "components/filter";
  @import "components/search";
  @import "components/options";
  @import "components/legend";
  @import "components/panel";
  @import "components/map";
  @import "components/tab";
  @import "components/tag";
  @import "components/fields";
  @import "components/photo";
  @import "components/modal";
  @import "components/schema";
  @import "components/tooltip";
  @import "components/breadcrumbs";
  @import "components/loader";
  @import "components/shop";
}
