/*--------------------
  LEGEND
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-loader {
  position: absolute;
  inset: 0;
  z-index: 1010;
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 24px;

  @include media($tabletL) {
    border-radius: 8px;
  }

  &.standard {
    position: relative;
    @include size(90px);
    margin: 0 auto;
    background: transparent;
  }

  &.map {
    background-color: rgba($white, 0.5);
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    @include size(90px);
    transform: translate(-50%, -50%);
    border: 6px solid $brand11;
    border-color: $brand11 transparent $brand11 transparent;
    border-radius: 50%;
    animation: rotate 1s linear infinite;

    @include media($tabletL) {
      width: 60px;
      height: 60px;
    }
  }
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
