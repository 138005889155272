/*--------------------
  FILTER
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-filter {
  position: relative;
  width: 100%;
  max-width: 378px;
  padding: 32px;
  border-right: 2px solid $gray200;

  @include media($tabletL) {
    display: none;
  }

  &.open {
    @include media($tabletL) {
      position: fixed;
      z-index: 10200;
      top: 0;
      left: 0;
      display: block;
      width: 100vw;
      max-width: 100%;
      height: 100vh;
      padding: 0;
      background-color: $gray200;
      border: none;
      overflow: auto;
    }
  }

  &__header {
    margin-bottom: 24px;

    @include media($tabletL) {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding: 16px;
      background-color: $white;
    }

    .pochatok-im-button--close {
      display: none;

      @include media($tabletL) {
        display: block;
      }
    }
  }

  &__body {
    @include media($tabletL) {
      padding: 0 16px calc(16px + 76px);
    }

    &-wrapper {
      @include media($tabletL) {
        padding: 16px;
        background-color: $white;
        border-radius: 8px;
      }
    }
  }

  &__footer {
    margin-top: 24px;

    @include media($tabletL) {
      position: fixed;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      margin: 0;
      padding: 16px;
      background-color: $white;
    }

    &-buttons {
      > * {
        width: 100%;
      }
    }
  }

  &__counter {
    display: none;
    align-items: center;
    justify-content: center;

    @include size(20px);
    min-width: 20px;

    background-color: $brand11;
    border-radius: 90px;

    @include media($tabletL) {
      display: flex;
    }
  }

  &__title {
    @include media($tabletL) {
      margin-right: 8px;
    }

    span {
      @include media($tabletL) {
        display: none;
      }
    }
  }

  &__search {
    margin-bottom: 24px;

    @include media($tabletL) {
      margin-bottom: 16px;
    }
  }
}
