/*--------------------
  TAG
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-tag {
  letter-spacing: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 4px;
  border-radius: 100%;

  &--border {
    border: 1px solid $white;
  }

  &--1 {
    background-color: $fruits-vegetables;
  }

  &--2 {
    background-color: $fish;
  }

  &--3 {
    background-color: $bacalia;
  }

  &--4 {
    background-color: $gallery;
  }

  &--5 {
    background-color: $meat;
  }

  &--6 {
    background-color: $restaurant;
  }

  &--7,
  &--8,
  &--9,
  &--13 {
    background-color: $gray300;
  }

  &--10 {
    background-color: $covered-market;
  }

  &--11,
  &--12 {
    background-color: $open-pavilion;
  }

  &--14 {
    background-color: $manufactury;
  }

  &--novapost,
  &--ukrpost,
  &--electro,
  &--terminal,
  &--cafe,
  &--coffee,
  &--bakery,
  &--market-place,
  &--parking,
  &--driveway,
  &--pedestrians-place,
  &--wc {
    font-size: 0;
  }

  &--market-place {
    background-color: $open-pavilion;
  }

  &--bacalia {
    background-color: $bacalia;
  }

  &--fish {
    background-color: $fish;
  }

  &--meat {
    background-color: $meat;
  }

  &--manufactury {
    background-color: $manufactury;
  }

  &--pedestrians-place {
    background-color: $gray300;
  }
}
