/* --------------------
  VARIABLES
-------------------- */

// COLOR
$white: #ffffff;
$black: #000000;

$gray600: #29332D;
$gray500: #47594F;
$gray400: #93A39A;
$gray300: #DAE5DF;
$gray200: #F0F5F2;

$brand11: #FACD00;
$brand12: #FAE897;
$brand13: #FFFAE5;
$brand21: #536614;
$brand22: #D0E58A;
$brand23: #F1FAD4;

$fruits-vegetables: #E7F2C2;
$fish: #C7F2F2;
$bacalia: #FAE9C8;
$gallery: #C2E6F2;
$meat: #FACDD4;
$restaurant: #FAD9C8;
$covered-market: #D2F2C2;
$open-pavilion: #C2F2CA;
$manufactury: #FAF2C8;

// FONT
$ff-base: "Montserrat", "Arial", sans-serif;
$fw-base: 500;
$fs-base: normal;
$fz-base: 16px;
$lh-base: 24px;

// MEDIA
$mobileS: 320px;
$mobileM: 375px;
$mobileL: 425px;
$tabletS: 480px;
$tabletM: 640px;
$tabletL: 768px;
$laptopS: 992px;
$laptopM: 1024px;
$laptopL: 1200px;
$fullHD: 1920px;

// COMPONENTS STYLES VARIABLES
$container: 1200px;
