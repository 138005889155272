/*--------------------
  BUTTON
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: $gray600;
  text-decoration: none;
  border-radius: 100px;
  border: 2px solid $gray200;
  background-color: transparent;
  cursor: pointer;
  transition: border 0.15s linear, color 0.15s linear, background-color 0.15s linear, opacity 0.15s linear;

  &--default {
    padding: 0;
    border: none;
    border-radius: 0;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  svg {
    margin-right: 6px;
  }

  &:hover,
  &:focus {
    border-color: $gray300;
  }

  &--primary {
    background-color: $brand11;
    border-color: $brand11;

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: $brand11;
    }
  }

  &--secondary {
    background-color: transparent;
    border-color: $brand11;

    &:hover,
    &:focus {
      background-color: $brand11;
      border-color: $brand11;
    }
  }

  &--filter {
    display: none;

    @include media($tabletL) {
      display: flex;
    }
  }

  &--close {
    @include size(24px);
    padding: 0;
    margin-left: auto;
    font-size: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      margin: 0;
    }

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  &--scroll {
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: default;

    svg {
      margin: 0 0 0 12px;

      @include all-size(40px);
    }
  }
}

