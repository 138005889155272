/*--------------------
  LEGEND
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-legend-tag {
  border-top: 2px solid $gray200;
  padding: 32px;

  @include media($tabletL) {
    padding: 24px;
    border: none;
  }

  @include media($tabletS) {
    padding: 16px;
  }

  &__description {
    margin-bottom: 16px;
    font-weight: 700;

    @include media($tabletL) {
      margin-bottom: 12px;
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -16px;
    margin-left: -12px;

    @include media($tabletL) {
      display: block;
      margin-bottom: -8px;
      margin-left: 0;
    }

    > * {
      display: flex;
      margin-bottom: 16px;
      margin-left: 12px;

      @include media($tabletL) {
        margin-bottom: 8px;
        margin-left: 0;
      }

      > *:not(span) {
        text-align: left;
      }
    }
  }
}

.pochatok-im-legend-equipment {
  padding: 16px 32px;

  @include media($tabletL) {
    padding: 24px;
  }

  @include media($tabletS) {
    padding: 16px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-top: -8px;

    > * {
      margin-top: 8px;
      margin-left: 8px;
    }
  }

  &__link {
    text-align: center;
    width: 100%;
    max-width: 220px;
  }

  &__title {
    font-weight: 700;
    margin-bottom: 6px;
  }
}
