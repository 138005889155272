/*--------------------
  FIELDS
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-fields {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 6px;
  }

  &__input {
    height: 44px;
    padding: 10px;
    border: 2px solid $gray300;
    border-radius: 8px;

    &::placeholder {
      color: $gray400;
    }
  }
}

