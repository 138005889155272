/*--------------------
  TOOLTIP
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-tooltip {
  &__title {
    margin-bottom: 8px;
  }

  &__work {
    margin-bottom: 8px;

    &-caption {
      margin-bottom: 4px;
    }

    &-text {
      color: $gray500;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: -8px;
    margin-left: -8px;

    @include media($tabletL) {
      flex-wrap: wrap;
    }

    > * {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 8px;
    }
  }

  &__link {
    justify-content: space-between;
    max-width: 300px;
    width: 100%;

    svg {
      margin-right: 0;
      margin-left: 8px;
      transform: rotate(180deg);
    }
  }
}
