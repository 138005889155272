/*--------------------
  BREADCRUMBS
--------------------*/
@import "src/assets/styles/helpers/utils";

.pochatok-im-breadcrumbs {
  display: flex;
  align-items: center;
  padding: 24px 0;
  overflow: scroll;

  @include media($tabletL) {
    padding: 24px 0 16px;
  }

  &__separator {
    margin: 0 4px;
  }

  &__link {
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background-color: transparent;
    transition: color 0.15s linear;

    &:disabled {
      color: $gray500;
      pointer-events: none;
    }
  }
}

